// Your variable overrides
$sizzling-red: #4ECDC4;
$alice-blue: #F7FFF7;
$sea-green: #FF6B6B;
$prussian-blue: #1A535C;
$shark: #1A535C;
$pastel-yellow: #FFE66D;

$custom-colors: (
  'sizzling-red': $sizzling-red,
  'alice-blue': $alice-blue,
  'sea-green': $sea-green,
  'prussian-blue': $prussian-blue,
  'shark': $shark,
  'pastel-yellow': $pastel-yellow
);

// set new colors
$primary: $sizzling-red;
$info: $sea-green;

$theme-colors: (
  "primary":  $primary,
  "info": $info
);

$theme-colors: map-merge($theme-colors, $custom-colors);

// borders
$border-radius:               4px;
$border-radius-sm:            4px;
$border-radius-lg:            4px;
$border-radius-pill:          4px;

// remove the default focus from bootstrap
$btn-focus-box-shadow: none;
$input-btn-focus-width:         0;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-btn-focus-color-opacity: 0;

// Import bootstrap latest
// @import 'bootstrap';
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";