@import "./custom/custom";


body {
  background-color: $sea-green;
  font-family: 'Roboto', sans-serif;
}
.text-alice-blue {
  color: $alice-blue !important;
}

a:hover {
  color: $alice-blue !important;
}

.logo {
  width: 80px;
  height: auto;
}

.clip {
  clip-path: none;
  z-index: 2;
}

.hero {
  min-height: 100%;
  min-height: 100vh
}

.icon-big {
  font-size: 70px;
}

.icon-big:hover {
  position: absolute;
  font-size: 80px;
  color: $prussian-blue !important;
  transform: scale(1);
  -webkit-transform: scale(1);
  transition: opacity 500ms;
  -webkit-transition: opacity 500ms, -webkit-transform 500ms;
}

.vertical-center {
  min-height: 100%; 
  min-height: 100vh;

  display: flex;
  align-items: center;
}

.img-svg {
  width: 60px;
  height: auto;
}

.icon-big:hover {
  position: absolute;
  font-size: 80px;
  color: $prussian-blue !important;
  transform: scale(1);
  -webkit-transform: scale(1);
  transition: opacity 500ms;
  -webkit-transition: opacity 500ms, -webkit-transform 500ms;
}

.g-hover:hover {
  fill: $prussian-blue !important;
}

.img-svg:hover {
  position: absolute;
  width: 70px;
  transform: scale(1);
  -webkit-transform: scale(1);
  transition: opacity 500ms;
  -webkit-transition: opacity 500ms, -webkit-transform 500ms;
}

.nav-text {
  font-size: 20px;
  letter-spacing: 2px;
}

.nav-text:hover {
  color: $alice-blue !important;
}

.img-card {
  width: 100%;
  height: 300px;
}

.pointer {
  cursor: pointer;
}

.text-sea-green-no-hover:hover {
  color: $sea-green !important;
}

.text-hover-reverse:hover {
  color: $sea-green !important;
}

.text-hover-white:hover {
  color: $alice-blue !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgb(255 230 109 / 5%) !important;
}

.text-md {
  font-size: 18px;
}

.tags {
  list-style: none;
  margin: 0;
  overflow: hidden; 
  padding: 0;
}

.tags li {
  float: left; 
}

.tag {
  background: $primary;
  border-radius: 3px 0 0 3px;
  color: $alice-blue !important;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
}

.tag::before {
  background: $alice-blue;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  background: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid $primary;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: $sea-green;
  color: $alice-blue !important;
}

.tag:hover::after {
  border-left-color: $sea-green; 
}

.image {
  top: 50%;
  left: 50%;
  -webkit-animation:spin 10s linear infinite;
  -moz-animation:spin 10s linear infinite;
  animation:spin 10s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@include media-breakpoint-up(md) { 
  .clip {
    clip-path: polygon(0% 0%, 100% 0%, 100% 66%, 0% 100%);
  }

  .text-md {
    font-size: 18px;
  }
}

@include media-breakpoint-down(sm) {
  .image {
    width: 70%;
  }
}

// .grid {
// 	position: relative;
// 	clear: both;
// 	margin: 0 auto;
// 	padding: 1em 0 4em;
// 	max-width: 1000px;
// 	list-style: none;
// 	text-align: center;
// }

// /* Common style */
// .grid figure {
// 	position: relative;
// 	float: left;
// 	overflow: hidden;
// 	margin: 10px 1%;
// 	min-width: 320px;
// 	max-width: 480px;
// 	max-height: 360px;
// 	width: 48%;
// 	height: auto;
// 	background: #3085a3;
// 	text-align: center;
// 	cursor: pointer;
// }

// .grid figure img {
// 	position: relative;
// 	display: block;
// 	min-height: 100%;
// 	max-width: 100%;
// 	opacity: 0.8;
// }

// .grid figure figcaption {
// 	padding: 2em;
// 	color: #fff;
// 	text-transform: uppercase;
// 	font-size: 1.25em;
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// }

// .grid figure figcaption::before,
// .grid figure figcaption::after {
// 	pointer-events: none;
// }

// .grid figure figcaption,
// .grid figure figcaption > a {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// }

// /* Anchor will cover the whole item by default */
// /* For some effects it will show as a button */
// .grid figure figcaption > a {
// 	z-index: 1000;
// 	text-indent: 200%;
// 	white-space: nowrap;
// 	font-size: 0;
// 	opacity: 0;
// }

// .grid figure h2 {
// 	word-spacing: -0.15em;
// 	font-weight: 300;
// }

// .grid figure h2 span {
// 	font-weight: 800;
// }

// .grid figure h2,
// .grid figure p {
// 	margin: 0;
// }

// .grid figure p {
// 	letter-spacing: 1px;
// 	font-size: 68.5%;
// }

// figure.effect-hera {
// 	background: none;
//   opacity: none!important;
// }

// figure.effect-hera:hover{
//   background:rgba(255, 230, 109, 1);
// }

// figure.effect-hera h2 {
// 	font-size: 158.75%;
// }

// .massive-effect:hover {
//   background: rgba(255, 230, 109, 1);
// }

// .no-background {
//   background:  none !important;
// }

// figure.effect-hera h2,
// figure.effect-hera p {
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
// 	transition: opacity 0.35s, transform 0.35s;
// 	-webkit-transform: translate3d(-50%,-50%,0);
// 	transform: translate3d(-50%,-50%,0);
// 	-webkit-transform-origin: 50%;
// 	transform-origin: 50%;
// }

// figure.effect-hera figcaption::before {
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	width: 200px;
// 	height: 200px;
// 	border: 2px solid #4ECDC4;
// 	content: '';
// 	opacity: 0;
// 	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
// 	transition: opacity 0.35s, transform 0.35s;
// 	-webkit-transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-45deg) scale3d(0,0,1);
// 	transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-45deg) scale3d(0,0,1);
// 	-webkit-transform-origin: 50%;
// 	transform-origin: 50%;
// }

// figure.effect-hera p {
// 	width: 100px;
// 	text-transform: none;
// 	font-size: 121%;
// 	line-height: 2;
// }

// figure.effect-hera p a {
// 	color: rgb(78, 205, 196);
  
// }

// figure.effect-hera p a:hover,
// figure.effect-hera p a:focus {
// 	opacity: 0.6;
// }

// figure.effect-hera p a i {
// 	opacity: 0;
// 	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
// 	transition: opacity 0.35s, transform 0.35s;
// }

// figure.effect-hera p a:first-child i {
// 	-webkit-transform: translate3d(-60px,-60px,0);
// 	transform: translate3d(-60px,-60px,0);
// }

// figure.effect-hera p a:nth-child(2) i {
// 	-webkit-transform: translate3d(60px,-60px,0);
// 	transform: translate3d(60px,-60px,0);
// }

// figure.effect-hera p a:nth-child(3) i {
// 	-webkit-transform: translate3d(-60px,60px,0);
// 	transform: translate3d(-60px,60px,0);
// }

// figure.effect-hera p a:nth-child(4) i {
// 	-webkit-transform: translate3d(60px,60px,0);
// 	transform: translate3d(60px,60px,0);
// }

// figure.effect-hera:hover figcaption::before {
// 	opacity: 1;
// 	-webkit-transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-45deg) scale3d(1,1,1);
// 	transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-45deg) scale3d(1,1,1);
// }

// figure.effect-hera:hover h2 {
// 	opacity: 0;
// 	-webkit-transform: translate3d(-50%,-50%,0) scale3d(0.8,0.8,1);
// 	transform: translate3d(-50%,-50%,0) scale3d(0.8,0.8,1);
// }

// figure.effect-hera:hover p i:empty {
// 	-webkit-transform: translate3d(0,0,0);
// 	transform: translate3d(0,0,0); /* just because it's stronger than nth-child */
// 	opacity: 1;
// }

.cursor-default{
  cursor: default;
}